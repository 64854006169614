PluginJS.Accordions = ( function ( $ ) {

  function init () {
    $( '.accordion-group:not(.initialized)' ).each( function () {
      let $group = $(this);

      $group.on( 'click', '.accordion-toggle', function () {
        let $item = $(this).closest( '.accordion-item' );
        let opening = !$item.hasClass( 'open' );
        console.log( $( '.accordion-content', $item ) );
        $(this).attr( 'aria-expanded', opening ? 'true' : 'false' );
        $( '.accordion-content', $item )[ opening ? 'slideDown' : 'slideUp' ]( 300 );
        $item.toggleClass( 'open', opening );
      });

      $( '.accordion-item', $group ).each( function () {
        $( '.accordion-content', this ).css( 'display', $(this).hasClass( 'open' ) ? 'block' : 'none' );
      });

      $group.addClass( 'initialized' );
    });
  }

  $( init );

  return {
    init
  }

})( jQuery );