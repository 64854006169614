/*--------------------------------------------*\
		Include Globally Useful JS Functions
\*--------------------------------------------*/
PluginJS.Functions = (function ($, root) {

	function send_ajax_request(args) {
		args.data.nonce = PluginJS.Variables.Ajax.nonce;
		$.post(PluginJS.Variables.Ajax.url, args.data, function (response) {
			response = JSON.parse(response);
			if (!response.hasOwnProperty('data')) { response.data = {}; }
			if (response.success && typeof args.success == 'function') {
				args.success(response.data);
			} else if (!response.success && typeof args.error == 'function') {
				args.error(response.data);
			}
		});
	}

	function wp_rest_request ( args ) {
		let ajax_args = {
			method: ( args.method ?? 'GET' ).toString().toUpperCase(),
			url: `${ PluginJS.Variables.WP_REST.root }${ args.endpoint }`,
			data: args.data,
			contentType: 'application/json; charset=utf-8',
			beforeSend ( xhr ) {
				xhr.setRequestHeader( 'X-WP-Nonce', PluginJS.Variables.WP_REST.nonce );
			},
			success ( data ) {
				if ( typeof args.success == 'function' ) {
					args.success( data );
				}
			},
			error ( data ) {
				if ( typeof args.error == 'function' ) {
					args.error( data );
				}
			},
			complete ( data ) {
				if ( typeof args.complete == 'function' ) {
					args.complete( data );
			}
			}
		};
		if ( ajax_args.method != 'GET' ) {
			ajax_args.dataType = 'json';
			ajax_args.data = JSON.stringify( ajax_args.data );
		}
		jQuery.ajax( ajax_args );
	}

	function get_query_parameter(name, url) {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	let scrollbar_width = null;
	// taken from https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
	function get_scrollbar_width() {
		if (scrollbar_width === null) {
			let outer = document.createElement('div');
			outer.style.visibility = 'hidden';
			outer.style.overflow = 'scroll';
			outer.style.msOverflowStyle = 'scrollbar';
			document.body.appendChild(outer);
			let inner = document.createElement('div');
			outer.appendChild(inner);
			scrollbar_width = (outer.offsetWidth - inner.offsetWidth);
			outer.parentNode.removeChild(outer);
		}
		return scrollbar_width;
	}

	document.querySelector(':root').style.setProperty('--scrollbar-width', `${get_scrollbar_width()}px`);

	return {
		send_ajax_request,
		wp_rest_request,
		get_query_parameter,
		
	}

})(jQuery, this);